<template>
    <div>
        <b-container>
            <section v-if="getEventGeneric.length == 0 && loader" class="event-details mb-3">
                <Spinner size="medium" line-fg-color="#e91e63" />
            </section>
            <section v-else class="event-details">
                <b-row>
                    <b-col cols="12" lg="8">
                        <div class="sector">
                            <p class="event-details__type">{{ getEvent.type.text }}</p>
                            <h2 class="event-details__title">{{ getEvent.name }}</h2>
                            <div class="event-details__image" v-lazy:background-image="getEvent.url">
                                <div v-if="getEvent.ribbon" class="custom-ribbon custom-ribbon--right"><span class="custom-ribbon__text custom-ribbon__text--red">Featured</span>
                                </div>
                            </div>
                        </div>
                        <div class="sector">
                            <h3 class="event-details__subtitle">Description</h3>
                            <p v-html="getEvent.desc" class="event-details__text"></p>
                        </div>
                    </b-col>
                    <b-col cols="12" lg="4">
                        <div class="sector">
                            <div class="event-details__element">
                                <h3 class="event-details__subtitle">Location</h3>
                                <span class="event-details__info"><simple-line-icons icon="locationPin" size="small" color="#504e70" />{{ getEvent.address }} {{ getEvent.city }}, {{ getEvent.country.text }}</span>
                            </div>
                            <div class="event-details__element mb-0">
                                <h3 class="event-details__subtitle">Date</h3>
                                <span class="event-details__info"><simple-line-icons icon="calendar" size="small" color="#504e70" />{{ getEvent.date }}</span>
                            </div>
                        </div>
                        <div v-if="getEvent.email || getEvent.phone || getEvent.website" class="sector">
                            <div class="event-details__element mb-0">
                                <h3 class="event-details__subtitle">Contact</h3>
                                <span v-if="getEvent.email" class="event-details__info"><simple-line-icons icon="envelope" size="small" color="#504e70" /><a class="event-details__link" :href="'mailto:'+ getEvent.email">{{ getEvent.email }}</a></span>
                                <span v-if="getEvent.phone" class="event-details__info"><simple-line-icons icon="phone" size="small" color="#504e70" />{{ getEvent.phone }}</span>
                                <span v-if="getEvent.website" class="event-details__info"><simple-line-icons icon="globe" size="small" color="#504e70" /> <a class="event-details__link" :href="getEvent.website" target="blank">website</a></span>
                            </div>
                        </div>
                        <div class="sector">
                            <div class="event-details__element mb-0">
                                <h3 class="event-details__subtitle">Map location</h3>
                                <div class="event-details__map">
                                    <l-map :zoom="zoom" :center="[getEvent.lat, getEvent.lng]" :options="{zoomControl: false}">
                                    <l-tile-layer
                                    :url="url"
                                    layer-type="base"></l-tile-layer>
                                    <l-marker :lat-lng="[getEvent.lat, getEvent.lng]">
                                        <l-popup><b>{{ getEvent.name }}</b> <br> {{ getEvent.address }},<br> {{ getEvent.city }}, {{ getEvent.country.text }}</l-popup>
                                    </l-marker>
                                    <l-control-zoom position="topright"></l-control-zoom>
                                    </l-map>
                                </div>
                            </div>
                        </div>
                        <!-- <AsideAdvertisement /> -->
                    </b-col>
                </b-row>
            </section>
        </b-container>
        <LatestEventsList />
    </div>
</template>

<script>
import SimpleLineIcons from 'vue-simple-line'
import AsideAdvertisement from '@/components/AsideAdvertisement.vue'
import { mapGetters } from 'vuex'
import Spinner from 'vue-simple-spinner'
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LControlZoom
} from 'vue2-leaflet'
import LatestEventsList from '@/components/LatestEventsList.vue'
export default {
  components: {
    SimpleLineIcons,
    LatestEventsList,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LControlZoom,
    Spinner,
    AsideAdvertisement
  },
  data: function () {
    return {
      loader: true,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 16
    }
  },
  computed: {
    getEventGeneric () {
      return this.$store.getters.getEvent
    },
    getEvent () {
      return this.$store.getters.getEvent[0]
    }
  },
  beforeCreate () {
    this.$store.dispatch('getEvent', { id: this.$route.params.id })
  },
  created () {
    setTimeout(function () { this.loader = false }.bind(this), 5000)
  }
}
</script>
