<template>
    <div>
        <b-container v-if="!getEventsPending">
            <div class="mb-3">
                <Spinner size="medium" line-fg-color="#e91e63" />
            </div>
        </b-container>
        <b-container v-else>
            <section v-if="getFilteredEvents.length != 0 && getEventsPending" class="events pt-0">
                <div class="sector pb-0 mb-0">
                    <h2 class="main-title">Latest Events</h2>
                    <p class="main-subtitle">Take a peek at the latest events</p>
                    <b-row>
                        <b-col v-for="event in orderBy(getFilteredEvents, 'id', -1)" :key="event.id"  cols="12" md="6" lg="4">
                            <div class="events__element">
                                <div v-if="event.ribbon" class="custom-ribbon custom-ribbon--right">
                                    <span class="custom-ribbon__text custom-ribbon__text--red">Featured</span>
                                </div>
                                <div class="events__image" :style="{ backgroundImage: 'url(' + event.url + ')' }"></div>
                                <div class="events__content">
                                    <p class="events__type">{{ event.type.text }}</p>
                                    <h2 class="events__title" v-line-clamp:22="1">{{ event.name }}</h2>
                                    <p class="events__text" v-html="event.desc" v-line-clamp:24="3"></p>
                                    <b-row>
                                        <b-col cols="6">
                                            <span v-line-clamp:24="2" class="events__place"><span class="agencies__star-tooltip" v-b-tooltip title="Location"><simple-line-icons icon="locationPin" size="small" color="#504e70" /></span>{{ event.city }}, {{ event.country.text }}</span>
                                        </b-col>
                                        <b-col cols="6">
                                            <span class="events__date"><span class="agencies__star-tooltip" v-b-tooltip title="Date"><simple-line-icons icon="calendar" size="small" color="#504e70" /></span>{{ event.date }}</span>
                                        </b-col>
                                    </b-row>
                                </div>
                                <div class="events__details">
                                    <button @click="redirectToLatest(event.id)" class="details-button details-button--full"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />View details</button>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row class="justify-content-center mb-4">
                        <router-link to="/events" class="details-button details-button--red"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />View more</router-link>
                    </b-row>
                </div>
            </section>
        </b-container>
    </div>
</template>

<script>
import SimpleLineIcons from 'vue-simple-line'
import { mapGetters } from 'vuex'
import Spinner from 'vue-simple-spinner'
import router from '../router/router'
import moment from 'moment'
import Vue2Filters from 'vue2-filters'
export default {
  components: {
    SimpleLineIcons,
    Spinner
  },
  mixins: [Vue2Filters.mixin],
  computed: {
    ...mapGetters([
      'getLatestEvents',
      'getEventsPending'
    ]),
    getFilteredEvents () {
      var events = Object.values(this.getLatestEvents).filter((event) => {
        return (new Date(moment(event.date, 'DD-MM-YYYY').format('YYYY-MM-DD')).setHours(0, 0, 0, 0) >= new Date().setDate(new Date().getDate() - 1) && !event.id.includes(this.$route.params.id))
      })

      var spliced = events.reverse().slice(0, 3)

      return spliced
    }
  },
  methods: {
    redirectToLatest (routeId) {
      router.push({ name: 'event', params: { id: routeId } })
      this.$store.dispatch('getEvent', { id: routeId })
    }
  },
  beforeCreate () {
    this.$store.dispatch('getLatestEvents')
  }
}
</script>
